.REKT-game-splash {
    /* background-color: rgba(255, 255, 255, 1); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .REKT-game-splash .overlap-group-wrapper {
    background: linear-gradient(180deg, rgb(255, 170.64, 206.07) 0%, rgb(218.61, 57.07, 96.69) 100%);
    background-color: rgba(255, 255, 255, 1);
    height: 100vh;
    width: 100%;
  }
  
  .REKT-game-splash .overlap-group {
    height: 97%;
    position: relative;
    top: 15px;
  }
  
  .REKT-game-splash .rectangle {
    /* background: linear-gradient(180deg, rgb(255, 203.55, 229.27) 0%, rgb(255, 98.63, 154.92) 100%); */
    background: #121212;
    border-radius: 42px;
    height: 97%;
    left: 18px;
    position: absolute;
    top: 0;
    width: 92%;
  }
  
  .REKT-game-splash .bitcoin {
    height: 95px;
    right: 0;
    position: absolute;
    top: 96px;
    width: 45px;
  }
  
  .REKT-game-splash .frame {
    height: 87px;
    right: 0;
    position: absolute;
    top: 40px;
    width: 45px;
  }
  
  .REKT-game-splash .image {
    /* height: 486px; */
    right: 0;
    object-fit: cover;
    position: absolute;
    /* top: 343px; */
    bottom: 0;
    width: 279px;
  }
  
  .REKT-game-splash .img {
    height: 108px;
    left: 0;
    position: absolute;
    /* top: 681px; */
    width: 90px;
    bottom: 35px;
  }
  
  .REKT-game-splash .group {
    /* height: 84px; */
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 83px;
    width: 112px;
  }
  
  .REKT-game-splash .bitcoin-2 {
    height: 114px;
    left: 10px;
    position: absolute;
    top: 225px;
    width: 125px;
  }
  
  .REKT-game-splash .text-wrapper {
    color: #F92B8C;
    font-family: "Plus Jakarta Sans", Helvetica;
    font-size: 17px;
    font-weight: 700;
    height: 28px;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 0;
    line-height: 27.7px;
    position: absolute;
    text-align: center;
    top: 133px;
    white-space: nowrap;
  }
  
  .REKT-game-splash .frame-2 {
    height: 118px;
    left: 0;
    position: absolute;
    top: 292px;
    width: 63px;
  }
  