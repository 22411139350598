.game-page {
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
*::-webkit-scrollbar {
  width: 0;
  height: 6px;
  background-color: #232734;
}
*::-webkit-scrollbar-thumb {
  background-color: #49485d;
  border-radius: 12px;
}

.form-group-manual .form-control::placeholder {
  opacity: 0.6;
}

.form-group-manual .form-control,
.form-group-manual .form-control:focus {
  color: #fff !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  background: var(--input-bg);
  border: 0 !important;
  height: 38px;
  box-shadow: none;
  padding: 10px;
}

.form-group-manual .form-control:disabled {
  opacity: 0.7;
  background: var(--input-bg);
}

.form-bet-input {
  width: 100% !important;
  position: relative;
}

.form-cashout-input {
  width: 100% !important;
  margin-right: 8px !important;
}

.form-group-manual .form-control::placeholder {
  color: #fff !important;
}

.game-body {
  grid-gap: 0;
  font-family: Space Mono;
  --gameplay-background-color: #292929;
  display: grid;
  /* gap: 30px; */
  grid-template-columns: 400px 1fr;
  margin-top: 14px;
  height: 100%;
}

.toggle-section label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 17px;
  height: 17px;
  background: #fcb122;
  border-radius: 50%;
  transition: 0.5s;
}

.toggle-section input:checked + label:after {
  left: calc(100% - 4px);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background: #1a1f40;
}

.toggle-section label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 28px;
  display: block;
  border-radius: 100px;
  position: relative;
  margin-bottom: 0;
  margin-left: auto;
  border-radius: 30px;
  background-color: #1a1f40;
  border: 1px solid #fcb122;
  padding-bottom: 0 !important;
  /* pointer-events: none; */
}

.toggle-section input:checked + label {
  background: #fcb122;
  border: 1px solid #fcb122;
}

.form-bet-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 8px 12px;
}

.toggle-section input {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.bet-input-btn {
  display: flex;
  /* gap: 8px;  gap don't work on some old-browser ios devices */
  height: 38px;
  padding-left: 8px;
  transition: opacity 0.2s ease;
}

.cashout-plus-btn,
.bet-max-btn,
.bet-2x-btn {
  margin-left: 8px !important;
}

.bet-input {
  position: relative;
  background: var(--input-field-bg);
  border-radius: 8px;
  /* padding: 8px 12px; */
  width: 100%;
}

.bet-input span.eth {
  position: absolute;
  left: 12px;
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
}
.bet-input-btn .btn,
.bet-input-btn .btn-primary:not(:disabled):not(.disabled):active,
.bet-input-btn .btn:focus,
.bet-input-btn .btn:hover {
  padding: 8px 13px;
  border-radius: var(--bs-border-radius);
  background: var(--input-btn);
  border: none !important;
  box-shadow: none;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px;
  text-align: center;
  margin: 0;
}
.bet-input-btn:active {
  color: #fff !important;
}

.bet-input-btn .btn:disabled,
.action-bet .btn:disabled {
  background: var(--input-btn);
}

.cash-out,
.icon-wrapper {
  cursor: pointer;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  transition: all 0.3s ease;
}
.icon-wrapper:hover {
  scale: 1.1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #fff !important;
  border: 2px solid #fcb122 !important;
  background: #fcb122 !important;
}

.form-label {
  color: var(--label-bg);
  font-family: Space Mono;
  display: block;
  font-size: 14px;
  line-height: 17px;
  margin: 0 !important;
  padding-bottom: 4px;
  font-weight: 500;
}

.cash-out,
.game-heading,
.game-lost {
  border-width: 1px;
  border-color: rgb(24 104 5);
  border-style: solid;
  border-radius: 8px;
  background-color: rgb(24 104 5);
  text-align: center;
  width: 100%;
  margin: 0px auto;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  max-width: 278px;
  height: 58px;
}

.game-heading h2 {
  font-size: 20px;
  color: #0c0e23;
  font-weight: 600;
  margin: 0;
}

.cash-out h2 {
  font-size: 14px;
  color: rgb(255 255 255);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cash-out img {
  margin: 0px 5px;
  width: 24px;
}

.cash-out span {
  color: rgb(255, 255, 255);
}

.game-lost h2 {
  font-size: 20px;
  color: rgb(0, 0, 0);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.game-lost img {
  margin: 0px 5px;
  width: 24px;
}

.game-lost {
  background: #fdf401 !important;
  border: none;
  box-shadow: 0 0 10px rgba(255, 176, 25, 0.4),
    inset 0 1px 0 hsla(0, 0%, 100%, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.15),
    inset 0 0 15px #ff8719;
}

.game-lost span {
  color: #000000;
  font-weight: bold;
}

.game-list-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  grid-gap: 10px;
  margin-bottom: 10px;
}

.game-box {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  text-align: center;
  padding: 11px 0px;
  width: 100%;
  border-radius: 6px;
  background-color: rgb(26 32 48);
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-box h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-box img {
  /* margin-right: 8px; */
  height: 35px;
}

.game-body .bet-btn .btn,
.game-body .bet-btn .btn:hover,
.game-body .bet-btn .btn:focus,
.game-body .bet-btn .btn-primary:not(:disabled):not(.disabled):active {
  /* background: linear-gradient(
    225deg,
    #77ffa8,
    #ffe081 33%,
    #ffad8e 68%,
    #a592ff
  ) !important; */
  border-radius: 8px;
  background: linear-gradient(136deg, #ff4f95 8.05%, #e70c75 95.93%);
  box-shadow: 0px 5px 9px 1px rgba(255, 79, 149, 0.69);
  text-transform: uppercase;
  border: none !important;
  border-radius: 7px;
  box-shadow: none;
  color: #fff !important;
  font-size: 22px;
  font-weight: 500;
  height: 60px;
  line-height: 23px;
  padding: 10px 20px;
  text-align: center;
  transition: all 0.5s;
  width: 100%;
  /* background: #f64dff !important;
    border: none;
    border-radius: 12px;
    box-shadow: none;
    color: #fff !important;
    font-size: 22px;
    font-weight: bold;
    height: 60px;
    line-height: 23px;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
    border: 2px solid #f771ff;
    box-shadow: 0px 4px 0px #9e2ea4;
    font-family: ;
    letter-spacing: 1px;
    transform: scale(1);
    transition: all ease-in-out 0.2s; */
}
/* .game-body .bet-btn .btn:hover {
    transform: scale(1.03);
  } */
.game-body .bet-btn .btn:hover {
  background: linear-gradient(136deg, #f50364 8.05%, #e70c75 95.93%) !important;
}

.form-group-manual {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 8px;
  width: 100%;
}
.game-body .bet-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 12px;
}

.game-bet-box {
  background-color: transparent !important;
  /* border: 1px solid var(--theme-border); */
  position: relative;
}
.crash-tab-manual,
.crash-bet-content {
  background: var(--input-field-bg);
  padding: 1.25rem 0;
  padding-bottom: 0;
  border-radius: 0 0 12px 12px;
}
.crash-bet-content .auto-bet.auto-bet-mode {
  padding-top: 0;
  padding-bottom: 25px;
}
.level-btn {
  border-width: 1px;
  border: 0.4px solid #1a2030 !important;
  border-style: solid;
  border-radius: 8px;
  background-color: #1a2030;
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  width: 100%;
}

.level-btn .btn,
.level-btn .btn:hover,
.level-btn .btn:focus,
.level-btn .btn-primary:not(:disabled):not(.disabled):active {
  background: #363943;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  color: #999999;
  width: 100%;
  height: 45px;
}

.level-btn .btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.game-body .change-seed {
  position: unset;
  bottom: unset;
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.game-body .change-seed .btn,
.game-body .change-seed .btn:hover,
.game-body .change-seed .btn:focus,
.game-body .change-seed .btn-primary:not(:disabled):not(.disabled):active {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  padding: 11px 12px;
}

.game-page .dice-tab-grid {
  margin-top: 80px;
}
.rekt-bet-box .bet-input-btn button:last-child {
  border-right: none !important;
}
.level-btn .active-level-btn,
.level-btn .active-level-btn:hover,
.level-btn .active-level-btn:focus {
  color: #ffffff;
  background-color: rgb(14 19 34) !important;
  border-radius: 0px !important;
}

.level-btn .active-level-btn:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.level-btn .active-level-btn:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.level-btn button {
  background: transparent !important;
}

.game-box.winner-game-box img {
  margin: 0px;
}

.game-box {
  cursor: pointer;
}

.lose-game-game .game-box {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  background: #23252b;
  opacity: 0.5;
}

.lose-game-game .winner-game-row .game-box {
  border-radius: 6px;
  /* background-color: rgb(25, 27, 45); */
  background-color: rgb(25, 27, 45);
  /* opacity: 0.702; */
}

.game-box.winner-game-box {
  background: radial-gradient(
      100% 100% at 50% 0%,
      rgba(129, 240, 31, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(0deg, rgba(129, 240, 31, 0.25), rgba(129, 240, 31, 0.25)),
    #363943 !important;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.lose-game-game .game-box {
  background-color: rgb(31 35 48);
}

.game-list-row.winner-game-row:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: url("../../assets/images/game/arrow-left.svg");
  left: -25px;
  top: 15px;
  background-repeat: no-repeat;
}

.game-list-row.winner-game-row:after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: url("../../assets/images/game/arrow-right.svg");
  right: -31px;
  top: 15px;
  background-repeat: no-repeat;
}

.game-list-row.winner-game-row {
  position: relative;
}

.triangle-6 {
  width: 60px;
  height: 30px;
  border-top: solid 30px rgb(200, 30, 50);
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
  position: absolute;
}

.triangle.triangle1.triangle-6 {
  left: 8px;
  transform: rotate(-15deg);
  top: -10px;
  width: 15px;
  height: 20px;
  border-top: solid 29px #16181e;
  border-left: solid 16px transparent;
  border-right: solid 0px transparent;
  position: absolute;
}

.triangle.triangle2.triangle-6 {
  right: 40px;
  transform: rotate(35deg);
  top: -18px;
  width: 15px;
  height: 20px;
  border-top: solid 29px #16181e;
  border-left: solid 16px transparent;
  border-right: solid 0px transparent;
  position: absolute;
}

.triangle.triangle3.triangle-6 {
  right: -11px;
  transform: rotate(-209deg);
  bottom: 0px;
  width: 15px;
  height: 20px;
  border-top: solid 29px #1a1c22;
  border-left: solid 16px transparent;
  border-right: solid 0px transparent;
  position: absolute;
}

.bet-input.pdd-200 i {
  position: absolute;
  color: rgb(81, 226, 245);
  font-size: 20px;
  left: 17px;
}

.bet-input .form-field {
  width: 100%;
}
/* ********************************************** */

.bet-list {
  background: var(--user-table-bg);
  position: relative;
  font-family: Space Mono;
  border-radius: 12px;
  min-height: 340px;
  overflow: hidden;
}

.bet-header h4 span {
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  font-weight: 600;
}

.bet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1f2330;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
  border-bottom: 2px solid #141620;
}

.bet-header:hover {
  background: #141620;
}
.bet-header .btn {
  width: 100%;
  display: flex;
  padding: 15px 15px;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-color: transparent;
}

.bet-header .btn:hover,
.bet-header .btn:focus,
.bet-header .btn-primary:not(:disabled):not(.disabled):active {
  background: transparent;
  border-color: transparent;
  box-shadow: none !important;
}

.bet-header span {
  color: #999999;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  text-align: right;
}

.bet-header h4 {
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.bet-amount {
  display: flex;
  font-family: Space Mono;
  align-items: center;
  font-size: 17px;
  color: #bcbcbc;
  justify-content: space-between;
  background: var(--user-table-header-bg);
  padding: 16px;
  border-radius: 7px 7px 0 0;
  /* border-bottom: 1px solid #313131; */
}

.bet-list ul li:nth-of-type(even) {
  background: var(--user-table-header-bg);
}

.bet-list ul {
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  display: grid;

  width: 100%;
  /* padding: 10px 0px; */
}
.bet-list ul li {
  padding: 10px 20px;
}
.bet-list ul::-webkit-scrollbar-track {
  background-color: #21264a;
}

.bet-list ul::-webkit-scrollbar {
  width: 0px;
  background-color: #21264a;
}

.bet-list ul::-webkit-scrollbar-thumb {
  background-color: #21264a;
}

.bet-amount span img {
  width: 10px;
  margin-right: 7px;
}
.bet-amount span {
  display: flex;
  align-items: center;
  color: #fff;
  line-height: 17px;
  /* font-weight: 600; */
}
.bet-amount span svg {
  height: 28px;
  width: 28px;
}
.bet-amount span {
  gap: 10px;
}

.bet-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 15px; */
  font-size: 14px;
  color: #ffffff;
  /* border-top: 1px solid #292929; */
}

.bet-user-amount-fail {
  color: #ff3c2f;
  display: flex;
  align-items: center;
}
.bet-user-amount {
  color: #ffffff;
  display: flex;
  align-items: center;
}
.bet-user-amount-success {
  color: #00e06f;
  display: flex;
  align-items: center;
}

.bet-user-amount img {
  width: 10px;
  margin-right: 5px;
}

.current-user-row {
  font-weight: 600;
}

.bet-user {
  font-family: Space Mono;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100px;
}
.bet-user h5 {
  margin: 0;
  font-size: 14px;
}
.bet-user img {
  width: 25px;
  margin-right: 10px;
  /* border: 1px dashed #414251; */
  /* height: 30px; */
  object-fit: cover;
  border-radius: 100%;
}

@keyframes cash {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/* *********************************************** */
.game-screen {
  display: flex;
  /* align-items: flex-end; */
  background: var(--chart-bg);
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 20px 50px;
  /* padding-left: 50px; */
}

.crash-multi-line {
  min-height: 100vh;
  width: 10px;
  height: 100%;
  background: #2b2b2b;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* .crash-multi-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
} */
.crash-bet-box {
  padding: 5px 5px;
  background: #2b2b2b;
  color: #636363;
  border-radius: 4px;
  min-width: 50px;
  text-align: center;
  font-size: 12px;
}

/* .crash-bet-box:nth-child(2) {
  top: 128px;
}
.crash-bet-box:nth-child(3) {
  top: 256px;
}
.crash-bet-box:nth-child(4) {
  top: 384px;
}
.crash-bet-box:nth-child(5) {
  top: 512px;
}
.crash-bet-box:nth-child(6) {
  top: 640px;
} */

/***** game history  ************/
.dice-history .table thead th {
  border: none;
  font-weight: normal;
  line-height: 16px;
  padding: 16px 26px;
  font-size: 14px;
  color: rgb(81, 226, 245);
  font-weight: 500;
}

.dice-history .table thead {
  background-color: rgb(17, 19, 38);
}

.dice-history tbody,
.dice-history td,
.dice-history th,
.dice-history thead,
.dice-history tr {
  border: none !important;
}

.dice-history .table {
  color: #fff;
}

.dice-history tbody tr td:nth-child(1),
.dice-history tbody tr td:nth-child(2) {
  color: white !important;
}

.dice-history .table.table-striped tbody tr {
  border-bottom: 1px solid #363943;
}

.dice-history .table.table-striped tbody tr:last-child {
  border: none;
}

.dice-history .table.table-striped tbody tr:nth-of-type(even) {
  background-color: #1f2330 !important;
}

.dice-history .table-bordered td,
.dice-history .table-bordered th {
  border: none;
}

.dice-history .table-bordered td {
  font-weight: normal;
  line-height: 16px;
  text-align: left;
  padding: 10px 26px;
  vertical-align: middle;
  font-size: 14px;
  color: rgb(141, 142, 146) !important;
}

.dice-history .table.table-striped tbody tr td:nth-child(2) {
  color: #fff;
}

.dice-history .table.table-striped tbody tr td:first-child {
  color: #fff;
}

.dice-history .table-bordered td {
  padding: 17px 26px;
}

.dice-history .table.table-striped tbody tr td:last-child {
  color: #a8f55c;
}

.dice-history .table.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}

.game-page .dice-tab .nav-tabs {
  margin-bottom: 25px !important;
}

.game-list-row.game-list-four {
  grid-template-columns: repeat(4, minmax(0px, 1fr));
}

.game-list-row {
  pointer-events: none;
  cursor: not-allowed;
}

.winner-game-row {
  pointer-events: all !important;
}

.game-list-row.winner-game-row .game-box {
  background: #fdf401 !important;
  box-shadow: 0 0 10px rgba(255, 176, 25, 0.4),
    inset 0 1px 0 hsla(0, 0%, 100%, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.15),
    inset 0 0 15px #ff8719;
  transition: all ease 0.5s;
}

.game-list-row.winner-game-row .game-box:hover {
  transform: translateY(-5px);
}

.lose-game-game .win-box {
  background-color: #23293a;
  opacity: 1;
  border: 1px solid #404961;
}

.game-box.win-box.red-box {
  background: #ff00007d;
  border: 1px solid #db0000cf;
  opacity: 1 !important;
}

.game-screen-tiles.win-game-tile-red img {
  animation: neon 2s infinite;
}

@keyframes neon {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.disable-bet {
  opacity: 0.5;
  pointer-events: none;
}

.volume-btn {
  position: absolute;
  right: 23px;
  top: 12px;
}

.volume-btn i {
  color: #51e2f5;
  font-size: 30px;
  cursor: pointer;
}

button.disable-btn {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}

.rekt-tab {
  grid-row-gap: 20px;
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  position: relative;
  width: 100%;
}

.rekt-tab .nav-tabs .nav-item.show .nav-link,
.rekt-tab .nav-tabs .nav-link.active {
  background: #1a2030;
  border: none;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
  color: #fff;
  font-weight: 500;
}
li.nav-item {
  width: 50%;
}
.rekt-tab .nav-tabs .nav-link {
  border: none;
  border-radius: 4px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  max-height: 48px;
  padding: 16px 0;
  text-align: center;
  width: 100%;
}

.game-screen-sec {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 10px;
  height: 100%;
  width: 100%;
  user-select: none;
  cursor: default;
}

.game-screen-tiles {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 7px;
  border-style: solid;
  --uncovering-scale: 0.97;
  transition: transform 0s ease 0s, opacity 0s ease 0s;
  transform: scale(1);
  opacity: 1;
  cursor: default;
  border-width: 2px;
  border-color: transparent;
  background-color: #c776ff;
  background-size: 500% 500%;
  background-position: 0% 0%;
  height: 100%;
}

.target-tabs-data {
  display: flex;
  flex-direction: column;
  /* padding-top: 30px; */
  gap: 12px;
}
.target-tabs-data .nav-tabs {
  background-color: #444444;
}
.target-tabs-data .nav-tabs {
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  border-radius: 4px;
}
.target-tabs-data .nav-tabs .nav-link {
  border: none;
  border-radius: 8px;
  color: #c77dfa;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  max-height: 48px;
  padding: 16px 0;
  text-align: center;
  width: 100%;
  background: var(--input-bg);
}

.target-tabs-data .nav-tabs .nav-item.show .nav-link,
.target-tabs-data .nav-tabs .nav-link.active {
  background: var(--theme-blue-bg);
  border: none;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
  color: #fff;
  font-weight: 500;
}

.game-screen-tiles:hover {
  background-color: var(--header-bg);
}

.game-screen-tiles:active {
  transform: translateY(0px);
}

.game-screen .active-game-tile {
  opacity: 1 !important;
}

.game-screen .active-game-tile p {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #a8f55c;
  max-width: 92px;
  height: 29px;
  background: rgba(168, 245, 92, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 2px;
  margin: 0px auto;
  margin-bottom: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: -10px;
}

.game-screen .win-game-tile-red p {
  color: #fff;
  background: rgba(231, 38, 73, 0.25);
}

.game-screen .game-screen-tiles.active-game-tile img {
  height: 55px;
  width: 55px;
}

.game-screen .bet-input-btn {
  height: 41px;
}
.game-screen .nav-tabs {
  background: #23252b;
  border-radius: 4px;
}

.game-screen-tiles.win-game-tile-red {
  background: radial-gradient(
      100% 100% at 50% 0%,
      rgba(231, 38, 73, 0.5) 0%,
      rgba(231, 38, 73, 0) 100%
    ),
    rgb(26 32 48) !important;
  border-radius: 3px;
}
.game-screen-tiles.win-game-tile-green {
  background: radial-gradient(
      100% 100% at 50% 0%,
      rgba(169, 245, 92, 0.5) 0%,
      rgba(168, 245, 92, 0) 100%
    ),
    rgb(145 50 214) !important;
  border-radius: 7px;
  opacity: 0.8;
  pointer-events: none;
}
.mines-winner-loss {
  max-width: 204px;
  width: 100%;
  height: 92px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 23px;
  text-align: center;
  background: rgb(145 50 213);
  border-radius: 8px;
  border: 1px solid #b44eff;
  box-shadow: 0 0 10px #953bd6;
}
.mines-winner-box::before {
  content: "";
  position: absolute;
  background: rgba(231, 38, 73, 0.15);
  filter: blur(90px);
  width: 113px;
  height: 113px;
  border-radius: 50%;
}
.mines-right-section {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 100%;
}
.mines-winner-box h6 {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #a8f55c;
}

.mines-winner-box p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.game-screen-sec.winner-game {
  opacity: 0.25;
}
.mines-loss-box h6 {
  color: #e72649;
}
.mines-loss-box:before {
  position: absolute;
  width: 113px;
  height: 113px;
  background: rgba(231, 38, 73, 0.185);
  filter: blur(45px);
  content: "";
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.mines-winner-box::before {
  background: rgba(168, 245, 92, 0.15);
  filter: blur(45px);
  content: "";
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.game-screen-sec.show-all .game-screen-tiles:not(.revealed) {
  opacity: 0.5;
  visibility: visible;
  pointer-events: none;
}

/* ************************ Mine CSS End ******************* */

.crash-explode {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.crash-explode h4 {
  color: #ff524b;
  margin: 0;
  background: #2b2b2b;
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
}

.crash-explode img {
  transition: transform 0.1s ease-in-out, opacity 0.1s;
  max-width: 120px;
  margin: 0px auto;
  display: block;
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}

.action-bet {
  /* position: absolute; */
  display: flex;
  right: 10px;
  top: 60%;
  /* transform: translateY(-50%); */
  /* gap: 8px; */
  margin-left: 8px;
}

.action-bet .btn,
.action-bet .btn:hover,
.action-bet .btn:focus,
.action-bet .btn:first-child:active {
  background: var(--input-btn);
  box-shadow: none !important;
  border: none !important;
  width: 44.5px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-bet .btn img {
  width: 13px;
}

.react-p5 {
  height: 100%;
}

/********* media query ************/
@media screen and (max-width: 1660px) {
  .game-body {
    grid-template-columns: 340px 1fr;
  }
}

@media screen and (max-width: 1220px) {
  .coins-img img {
    height: 70px;
    margin-top: -30px;
  }

  .game-box {
    height: 40px;
  }

  .volume-btn i {
    font-size: 22px;
  }

  .cash-out,
  .game-heading,
  .game-lost {
    max-width: 240px;
    height: 45px;
  }
}
@media screen and (max-width: 1040px) {
  .game-body {
    grid-template-columns: 300px 1fr;
  }
}
@media screen and (min-width: 899px) {
  .game-body {
    grid-gap: 10px;
  }
}

@media screen and (max-width: 991px) {
  .dice-history .table-bordered td {
    white-space: nowrap;
  }
  .crash-multi-line {
    min-height: 50vh;
    margin-left: -20px;
  }

  .game-box {
    height: 40px;
  }

  .coins:before {
    background-size: 100% !important;
  }

  .coins-img img {
    height: 70px;
    margin-top: -30px;
  }

  .game-screen .active-game-tile p {
    font-size: 12px;
    font-weight: 400;
    margin-top: -20px;
  }

  .profit-content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.dollar-value-input {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #bbb;
  font-size: 12px;
}

.dollar-value-header {
  color: #bbb;
  font-size: 12px;
  line-height: 10px;
}

.modal-content {
  border-radius: 10px !important;
}

@media screen and (max-width: 900px) {
  .game-body {
    grid-template-columns: 1fr;
    padding-bottom: 30px;
  }
  .game-bet-box {
    order: 2;
  }

  .crash-explode img {
    width: 75px;
    margin: 0px auto;
    display: block;
  }
  .crash-explode h4 {
    padding: 6px 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .form-group-manual {
    width: 100% !important;
  }

  .game-screen {
    padding: 10px;
  }
  .game-inner-holder {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .game-holder {
    zoom: calc(100% / 1.2);
  }
  .cashout-btn button {
    height: 3rem;
    font-size: 12px;
  }
}
