@font-face {
  font-family: "Comfortaa";
  src: url(./assets/fonts/Comfortaa-Bold.ttf);
  font-weight: normal;
}

:root {
  --main-bg: linear-gradient(180deg, #353454 0%, #30404f 100%);
  --header-bg: #1b1f2b;
  --chart-bg: #121212;
  --input-field-bg: #1b1f2b;
  --user-bg: #151515;
  --label-bg: #ff6fa6;
  --chatbox-bg: #bd5eff;
  --input-bg: #2d3848;
  --input-btn: #535770;
  --user-table-bg: #2d3446;

  --user-table-header-bg: #262c3b;

  --theme-border: #bc5eff;
  --theme-blue-bg: #2e0e44;
}
html {
  background: var(--main-bg) !important;
}
body {
  margin: 0;
  font-family: "Comfortaa" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-bg) !important;
  font-size: 14px;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1440px !important;
  padding-left: 1.1rem !important;
  padding-right: 1.1rem !important;
}

code {
  font-family: "Comfortaa";
}

.btn-theme {
  background: #ff89e2; /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #ff89e2 0%,
    #ff76c8 52%,
    #ff75c7 100%
  ); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #ff89e2 0%,
    #ff76c8 52%,
    #ff75c7 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    ellipse at center,
    #ff89e2 0%,
    #ff76c8 52%,
    #ff75c7 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff89e2', endColorstr='#ff75c7',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  border: 1px solid #ff92ce !important;
  padding: 1rem 1.5rem;
  border-radius: 8px !important;
  font-size: 16px;
  letter-spacing: 1px;
  box-shadow: 0px 2px 0px #b5457d;
  text-shadow: 0px 1px 0px #b5457d;
  outline: none;
  position: relative;
  z-index: 1;
  color: #fff;
}

.btn-play {
  background-image: linear-gradient(
    to right,
    #fe6ea7,
    #ff7984,
    #ff935c,
    #ffb732,
    #f4dd10
  );
  font-family: "BOOMLogo";
  box-shadow: 0px 4px 0px #000000;
  text-shadow: 0px 2px 0px #000000;
  text-decoration: none;
  color: #fff;
  font-size: 27px;
  display: inline-block;
  line-height: 1;
  border-radius: 18px;
  padding: 1rem 2rem;
  min-width: 170px;
  min-height: 50px;
  border: 1px solid #000;
  letter-spacing: 1px;
}

.input-group > .form-control::placeholder {
  color: #fff;
}

.btn:focus-visible {
  box-shadow: none !important;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #fff !important;
  background: #3656ff !important;
  border-color: #3656ff !important;
}

.btn:active {
  border: 2px solid #3656ff !important;
  background: #3656ff !important;
}

/******************************/

:root {
  --window-height: 100vh;
}

* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: none;
  vertical-align: top;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: none;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  -webkit-transition: color 0.3s, background 0.3s;
  -o-transition: 0.3s color, 0.3s background;
  -moz-transition: 0.3s color, 0.3s background;
  transition: color 0.3s, background 0.3s;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input::-ms-clear {
  display: none;
}

img {
  display: block;
  max-width: 100%;
  pointer-events: none;
}

button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  font: inherit;
  appearance: none;
  display: block;
}

button::-webkit-input-placeholder,
input::-webkit-input-placeholder,
optgroup::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  font-family: inherit;
}

button:-moz-placeholder,
button::-moz-placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
optgroup:-moz-placeholder,
optgroup::-moz-placeholder,
select:-moz-placeholder,
select::-moz-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  font-family: inherit;
}

button:-ms-input-placeholder,
input:-ms-input-placeholder,
optgroup:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  font-family: inherit;
}

html {
  font: 10px/1.15 "Lato", sans-serif;
  -webkit-text-size-adjust: 100%;
  background: #0d1132;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
}

@media (orientation: landscape) and (min-aspect-ratio: 13/9) {
  html:not(.disable-scale).device-mobile {
    font-size: 9.5px;
  }
}

@media screen and (max-width: 374px) {
  html {
    font-size: 9px;
  }
}

body {
  font-size: 1.4rem;
  background: rgba(0, 0, 0, 0.18);
}

.device-mobile body {
  min-height: 100%;
  padding: calc(6rem + env(safe-area-inset-top, 0)) 0
    calc(5rem + env(safe-area-inset-bottom, 0));
}

@media (orientation: landscape) and (min-aspect-ratio: 13/9) {
  .device-mobile body {
    padding: 0 calc(5rem + env(safe-area-inset-right, 0)) 0
      env(safe-area-inset-left, 0);
  }
}

li {
  display: block;
}

#root,
body,
html {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 900px) {
  #root,
  body,
  html {
    height: 100%;
  }
}

.device-desktop #root,
.device-desktop body,
.device-desktop html {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  overflow: hidden;
}

.device-mobile #root {
  overflow-y: auto;
  overflow-x: hidden;
}

canvas {
  display: block;
  pointer-events: none;
}

.absolute-splash {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.pointer-events-none {
  pointer-events: none;
}

::-moz-selection {
  background: #3590fb;
  color: #fff;
  -webkit-text-fill-color: #fff;
}

::selection {
  background: #3590fb;
  color: #fff;
  -webkit-text-fill-color: #fff;
}

@-webkit-keyframes waitingArtFloat {
  50% {
    -webkit-transform: translate3d(0, -1rem, 0);
    transform: translate3d(0, -1rem, 0);
  }
}

@-moz-keyframes waitingArtFloat {
  50% {
    -moz-transform: translate3d(0, -1rem, 0);
    transform: translate3d(0, -1rem, 0);
  }
}

@-o-keyframes waitingArtFloat {
  50% {
    transform: translate3d(0, -1rem, 0);
  }
}

@keyframes waitingArtFloat {
  50% {
    -webkit-transform: translate3d(0, -1rem, 0);
    -moz-transform: translate3d(0, -1rem, 0);
    transform: translate3d(0, -1rem, 0);
  }
}

@-webkit-keyframes waitingPath {
  0% {
    stroke-dasharray: 0, 295.31px;
  }

  to {
    stroke-dasharray: 295.31px, 295.31px;
  }
}

@-moz-keyframes waitingPath {
  0% {
    stroke-dasharray: 0, 295.31px;
  }

  to {
    stroke-dasharray: 295.31px, 295.31px;
  }
}

@-o-keyframes waitingPath {
  0% {
    stroke-dasharray: 0, 295.31px;
  }

  to {
    stroke-dasharray: 295.31px, 295.31px;
  }
}

@keyframes waitingPath {
  0% {
    stroke-dasharray: 0, 295.31px;
  }

  to {
    stroke-dasharray: 295.31px, 295.31px;
  }
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-moz-keyframes rotate {
  to {
    -moz-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-o-keyframes rotate {
  to {
    -o-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  to {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -o-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.information-layer {
  /* background: rgba(12,12,56,.8); */
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  z-index: 10000000;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  -o-transition: 0.3s opacity, 0.3s visibility;
  -moz-transition: 0.3s opacity, 0.3s visibility;
  transition: opacity 0.3s, visibility 0.3s;
}

.information-layer.transparent-background {
  background: transparent;
}

.information-layer.show {
  visibility: visible;
  opacity: 1;
}

/* fixes-ady */

@media screen and (max-width: 500px) {
  /* working iphone css-- zoom issue- crash css zoom-attr was not working */
  ul.absolute-splash {
    top: 2% !important;
  }

  .game-inner-holder {
    max-height: 270px !important;
  }
  .game-holder {
    height: auto !important;
  }

  /* .game-line-chart-holder li:last-child {
  top: 0;
  bottom: auto;
  height: -webkit-calc(100% - 4rem);
  height: -moz-calc(100% - 4rem);
  height: calc(100% - 4rem);
} */

  /*others */
  .current-odd-holder {
    position: relative !important;
    top: 40% !important;
  }

  .crash-explode {
    margin-top: 14px !important ;
  }
  .current-odd {
    transform: scale(0.67) !important;
  }
  .waiting-circle {
    transform: scale(0.75) !important;
  }
}

@media screen and (max-width: 400px) {
  ul.absolute-splash {
    transform: scale(0.72) !important;
    transform-origin: left bottom;
    top: -1% !important;
  }
}

@media screen and (max-width: 300px) {
  ul.absolute-splash {
    transform: scale(0.55) !important;
    transform-origin: left bottom;
    top: -3% !important;
  }
  .game-inner-holder {
    max-height: 210px !important;
  }
}

.btn:disabled {
  pointer-events: none;
  /* opacity: 0.65 !important; */
  -webkit-filter: saturate(0.75) !important;
  filter: saturate(0.75) !important;
}
.crash-explode {
  zoom: 0.82;
}

/* bet history-modal css */
.modal {
  backdrop-filter: blur(2px);
  overflow: auto;
  font-family: Space Mono;
}
.modal-dialog {
  margin: 32px auto;
  padding: 20px;
}
.modal-header {
  border: 0;
  padding-bottom: 2px !important;
  padding-left: 15px;
  padding-right: 15px;
}

.modal-header .btn-close {
  filter: invert(1);
}
.modal-title {
  font-size: 2.2rem;
}
.modal-content {
  background-color: var(--user-table-bg);
  color: white;
}

.bet-history div {
  background-color: transparent;
  color: white;
}
.rdt_Table {
  background-color: var(--user-table-bg) !important;
  color: #2d3446;
}

.rdt_TableRow {
  padding-top: 8px;
  padding-bottom: 8px;
}

.rdt_TableRow:nth-child(even) {
  background-color: #262c3b;
}

.rdt_TableHeadRow {
  font-weight: bold;
  background-color: #1d2435 !important;
}
.rdt_TableHeadRow div {
  color: #4e6d9a !important;
  text-transform: uppercase;
}

/* .rdt_TableBody {
  max-height: 400px !important;
  overflow-y: auto !important;
} */

/* each heading class and row */
.rdt_TableCell,
.rdt_TableCol {
  justify-content: center !important;
  word-break: keep-all !important;
}

.payout-cell {
  position: relative;
  top: 1px;
}

@media screen and (max-width: 440px) {
  .modal-content {
    scale: 0.98;
  }

  .rdt_TableCell,
  .rdt_TableCol {
    min-width: auto !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .modal-content div {
    font-size: 10px !important;
  }
  .modal-content .rdt_TableHeadRow div {
    font-size: 11.6px !important;
  }
  .modal-content .modal-header div {
    font-size: 16px !important;
  }

  .bLXlIc {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media screen and (min-width: 900px) {
  .modal-dialog {
    min-width: 680px;
  }
}

/* /for sm screen mobile like- galaxy fold */
@media screen and (max-width: 352px) {
  .rdt_TableCell,
  .rdt_TableCol {
    padding-left: 4px !important;
    padding-right: 4px !important;
    transform: scale(0.8);
  }
}
/*  */

.bet-history-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 7px;
  backdrop-filter: blur(2px);
}
@media screen and (max-width: 440px) {
  .bet-history-btn {
    scale: 0.95;
  }
}
.bet-history-btn button {
  padding: 8px 18px;
  border-radius: 8px;
  font-weight: 600;

  color: white;
  background: linear-gradient(136deg, #ff4f95 8.05%, #e70c75 95.93%);
  transition: 0.1s ease-in-out;
}
.bet-history-btn button:active {
  transform: scale(0.95);
  background: linear-gradient(136deg, #f50465 8.05%, #b80059 95.93%);
}

.last-li-child {
  margin-bottom: 52px;
}

/*  */
.mute-btn {
  position: fixed;
  top: 80px;
  right: 12px;
  z-index: 100;
  display: grid;
  place-items: center;
  padding: 8px;
  border-radius: 50%;
  background: linear-gradient(136deg, #ff4f95 8.05%, #e70c75 95.93%);
  transition: 0.5s ease-in-out;
}

.mute-btn:active {
  transform: scale(0.9);
  background: linear-gradient(136deg, #ff569a 8.05%, #b80059 95.93%);
}
