.header {
  background: var(--header-bg);
  border-radius: 0px 0px 16px 16px;

  /* box-shadow: 0px 4px 0px 0px rgba(239, 110, 137, 0.24); */
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 999;
  height: 75px;
  display: flex;
  align-items: center;
}
.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blue-btn.btn,
.blue-btn.btn:hover,
.blue-btn.btn:focus {
  background: #3656ff !important;
  color: #fff;
  height: 40px;
  border: 2px solid #3656ff !important;
}

.logo a {
  color: #fff !important;
  font-size: 22px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo img {
  width: 74px;
}
@media screen and (min-width: 900px) {
  .logo img {
    width: 88px;
  }
}

.header_avatar img {
  width: 80%;
  height: 80%;
}

.headerUser {
  display: flex;
  /* align-items: center; */
  margin-top: 6px;
  gap: 8px;
  color: #636363;
}
@media screen and (max-width: 352px) {
  .headerUser {
    scale: 0.9;
    transform-origin: right;
  }
}

.header_avatar {
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 50%;
  transition: border-color 0.2s ease;
  overflow: hidden;
}

.header_avatar:hover {
  border: 2px solid #7c7c7c;
}
.headerUserBalance {
  color: #e31e7d;
}
.headerUserBalanceSuccess {
  color: #00e06f;
}
.headerUserBalanceFail {
  color: #ff3c2f;
}

.headerUserContent {
  /* min-width: 124px; */
  font-family: Space Mono;
}

.headerUserContent p {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0.2px;
  margin: 0;
  color: #fd027a;
}
.headerManage {
  display: flex;
  gap: 10px;
}
