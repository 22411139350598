.btn {
  overflow: hidden;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  position: relative;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  text-align: center;
  height: var(--size);
  min-width: var(--size);
  background: var(--c-2);
  color: var(--color);
  cursor: pointer;
  font: 700 var(--font-size), "Lato", sans-serif;
  text-transform: uppercase;
  -webkit-transition: -webkit-transform 0.2s, -webkit-filter 0.3s;
  transition: -webkit-transform 0.2s, -webkit-filter 0.3s;
  -o-transition: 0.3s filter, 0.2s -o-transform;
  -moz-transition: 0.2s transform, 0.3s filter, 0.2s -moz-transform;
  transition: transform 0.2s, filter 0.3s;
  transition: transform 0.2s, filter 0.3s, -webkit-transform 0.2s,
    -moz-transform 0.2s, -o-transform 0.2s, -webkit-filter 0.3s;
  width: 100%;
}

.btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  border-radius: inherit;
  z-index: 0;
  background: hsla(0, 0%, 100%, 0.1);
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -o-transition: 0.4s opacity;
  -moz-transition: 0.4s opacity;
  transition: opacity 0.4s;
  pointer-events: none;
}

@media (hover: hover) {
  .btn:hover:after {
    opacity: 1;
  }
}

.btn span {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  cursor: inherit;
  white-space: normal;
  word-break: break-word;
}

.btn span:empty {
  font-size: var(--icon-size);
}

.btn span:not(:empty) {
  padding: 0 calc(var(--size) / 3);
}

/* .btn:disabled {
  pointer-events: none;
  -webkit-filter: saturate(0);
  filter: saturate(0);
} */

.btn:active {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.btn.shadow {
  -webkit-box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.15);
}

.btn.loading {
  pointer-events: none;
}

.btn.loading span {
  color: transparent;
}

.btn.loading span:before {
  content: "";
  width: 3rem;
  height: 3rem;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  position: absolute;
  top: -webkit-calc(50% - 1.5rem);
  top: -moz-calc(50% - 1.5rem);
  top: calc(50% - 1.5rem);
  left: -webkit-calc(50% - 1.5rem);
  left: -moz-calc(50% - 1.5rem);
  left: calc(50% - 1.5rem);
  color: var(--color);
  font: var(--icon-size) / 1 "icomoon";
  -webkit-animation: rotate 0.3s linear infinite;
  -moz-animation: rotate 0.3s linear infinite;
  -o-animation: rotate 0.3s linear infinite;
  animation: rotate 0.3s linear infinite;
}

.btn.c-green {
  --c-1: #40a448;
  --c-2: #40a448;
  --color: #fff;
}

.btn.c-pink {
  --c-1: #ff6494;
  --c-2: #e20f6f;
  --color: #fff;
}

.btn.c-blue {
  --c-1: #4ddfff;
  --c-2: #2651f6;
  --color: #fff;
}

.btn.c-red {
  --c-1: #ff4364;
  --c-2: #a40c1f;
  --color: #fff;
}

.btn.c-orange {
  --c-1: #ffbf00;
  --c-2: #ff4124;
  --color: #fff;
}

.btn.c-cream {
  --c-1: #fff;
  --c-2: #ecc4cd;
  --color: #a3005b;
}

.btn.s-thin {
  --size: 2.6rem;
  --font-size: 1.6rem;
  --icon-size: 1rem;
}

.btn.s-extra-light {
  --size: 3.4rem;
  --font-size: 1.6rem;
  --icon-size: 1.2rem;
}

.btn.s-light {
  --size: 4rem;
  --font-size: 1.6rem;
  --icon-size: 1rem;
}

.btn.s-regular {
  --size: 4.2rem;
  --font-size: 1.2rem;
  --icon-size: 1.8rem;
}

.btn.s-medium {
  --size: 4.8rem;
  --font-size: 1.4rem;
  --icon-size: 1.8rem;
}

.btn.s-semi-black {
  --size: 5rem;
  --font-size: 1.6rem;
  --icon-size: 1.4rem;
}

.btn.s-black {
  --size: 6rem;
  --font-size: 1.4rem;
  --icon-size: 1.4rem;
}

.game-holder {
  width: 100%;
  height: 46rem;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  overflow: hidden;
  -webkit-transform: translateZ(1rem);
  -moz-transform: translateZ(1rem);
  transform: translateZ(1rem);
  /* -webkit-border-radius: 1.2rem;
    -moz-border-radius: 1.2rem;
    border-radius: 1.2rem;
    border: .3rem solid #397cff; */
  padding: 0.2rem;
  contain: paint;
  position: relative;
}

.game-holder canvas {
  width: 100%;
  height: 100%;
}

.game-inner-holder {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  position: relative;
  width: 100%;
  height: 45rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  border-radius: 0.8rem;
  overflow: hidden;
  -webkit-transform: translateZ(1rem);
  -moz-transform: translateZ(1rem);
  transform: translateZ(1rem);
  /* background: -webkit-gradient(linear,left top,left bottom,from(#2f48d7),color-stop(24%,#2539bb),color-stop(57%,#301f91),to(#261566));
    background: -webkit-linear-gradient(top,#2f48d7,#2539bb 24%,#301f91 57%,#261566);
    background: -moz-linear-gradient(top,#2f48d7,#2539bb 24%,#301f91 57%,#261566);
    background: -o-linear-gradient(top,#2f48d7,#2539bb 24%,#301f91 57%,#261566);
    background: linear-gradient(180deg,#2f48d7,#2539bb 24%,#301f91 57%,#261566) */
}

@media screen and (min-width: 900px) {
  .game-holder {
    height: 100%;
  }
  .game-inner-holder {
    height: 98%;
  }
}
@media screen and (min-width: 640px) {
  .game-inner-holder {
    justify-content: center;
  }
}

@media screen and (900px > width > 640px) {
  .chart-wrapper {
    width: 700px !important;
  }
}

.chart-wrapper {
  width: 855px;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}

.game-line-chart-holder {
  overflow: hidden;
  max-width: 0;
  bottom: 0;
  -webkit-transform: translate3d(0, 4rem, 0);
  -moz-transform: translate3d(0, 4rem, 0);
  transform: translate3d(0, 4rem, 0);
  pointer-events: none;
}

.startAnimations .game-line-chart-holder {
  -webkit-animation: game-line-chart-reveal 7s linear forwards;
  -moz-animation: game-line-chart-reveal 7s linear forwards;
  -o-animation: game-line-chart-reveal 7s linear forwards;
  animation: game-line-chart-reveal 7s linear forwards;
}

@-webkit-keyframes game-line-chart-reveal {
  to {
    max-width: 100%;
    -webkit-transform: translate3d(0, 2rem, 0);
    transform: translate3d(0, 2rem, 0);
  }
}

@-moz-keyframes game-line-chart-reveal {
  to {
    max-width: 100%;
    -moz-transform: translate3d(0, 2rem, 0);
    transform: translate3d(0, 2rem, 0);
  }
}

@-o-keyframes game-line-chart-reveal {
  to {
    max-width: 100%;
    transform: translate3d(0, 2rem, 0);
  }
}

@keyframes game-line-chart-reveal {
  to {
    max-width: 100%;
    -webkit-transform: translate3d(0, 2rem, 0);
    -moz-transform: translate3d(0, 2rem, 0);
    transform: translate3d(0, 2rem, 0);
  }
}

.booming.startAnimations .game-line-chart-holder {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.game-line-chart-holder li {
  overflow: hidden;
  width: 69rem;
  height: 45rem;
}

.game-line-chart-holder li svg {
  position: absolute;
  width: 79rem;
  height: 51.6rem;
  left: 0;
  top: 0;
}

.game-line-chart-holder li svg path {
  fill: none;
}

@media screen and (min-width: 900px) {
  .game-line-chart-holder li svg {
    width: 100rem;
    height: 64rem;
  }
  .game-line-chart-holder li {
    width: 90rem;
    height: 55rem;
    scale: 1.1;
  }
}

.game-line-chart-holder li svg path.glc-l {
  stroke: url(#lineGradient);
  stroke-width: 0.3rem;
}

.game-line-chart-holder li svg path.glc-g {
  fill: url(#pathGradient);
}

/* .game-line-chart-holder li:last-child {
  top: 0;
  bottom: auto;
  height: -webkit-calc(100% - 4rem);
  height: -moz-calc(100% - 4rem);
  height: calc(100% - 4rem);
} */

.game-line-chart-holder li:last-child svg {
  left: -0.4rem;
  top: -0.4rem;
}

.current-odd {
  font-family: Space Mono !important;
}

.current-odd-holder {
  position: absolute;
  top: 30%;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0) scale(0.7);
  -moz-transform: translate3d(-50%, 0, 0) scale(0.7);
  transform: translate3d(-50%, 0, 0) scale(0.7);
  opacity: 0;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, -webkit-transform 0.4s;
  -o-transition: 0.4s opacity, 0.4s -o-transform;
  -moz-transition: 0.4s transform, 0.4s opacity, 0.4s -moz-transform;
  transition: transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s,
    -moz-transform 0.4s, -o-transform 0.4s;
}

.current-odd-holder.active {
  -webkit-transform: translate3d(-50%, 0, 0) scale(1);
  -moz-transform: translate3d(-50%, 0, 0) scale(1);
  transform: translate3d(-50%, 0, 0) scale(1);
  opacity: 1;
}

.current-odd-holder .current-odd-glow {
  width: 41rem;
  height: 37.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  /* background: url(/static/media/glow.7f0b5899.png) no-repeat 50% 50%/contain */
}

.startAnimations .current-odd-holder .current-odd-glow {
  -webkit-animation: current-odd-glow 1s linear infinite;
  -moz-animation: current-odd-glow 1s infinite linear;
  -o-animation: current-odd-glow 1s infinite linear;
  animation: current-odd-glow 1s linear infinite;
}

@-webkit-keyframes current-odd-glow {
  0%,
  to {
    opacity: 0.5;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
    transform: translate3d(-50%, -50%, 0) scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1.5);
    transform: translate3d(-50%, -50%, 0) scale(1.5);
  }
}

@-moz-keyframes current-odd-glow {
  0%,
  to {
    opacity: 0.5;
    -moz-transform: translate3d(-50%, -50%, 0) scale(1);
    transform: translate3d(-50%, -50%, 0) scale(1);
  }

  50% {
    opacity: 1;
    -moz-transform: translate3d(-50%, -50%, 0) scale(1.5);
    transform: translate3d(-50%, -50%, 0) scale(1.5);
  }
}

@-o-keyframes current-odd-glow {
  0%,
  to {
    opacity: 0.5;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }

  50% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1.5);
  }
}

@keyframes current-odd-glow {
  0%,
  to {
    opacity: 0.5;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
    -moz-transform: translate3d(-50%, -50%, 0) scale(1);
    transform: translate3d(-50%, -50%, 0) scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1.5);
    -moz-transform: translate3d(-50%, -50%, 0) scale(1.5);
    transform: translate3d(-50%, -50%, 0) scale(1.5);
  }
}

.current-odd-holder .current-odd {
  font: 5.6rem/1 "Baloo", sans-serif;
  color: #fff;
  position: relative;
  text-shadow: 0 0.2rem 0.4rem rgba(11, 19, 54, 0.95);
  -webkit-transition: min-width 0.4s, color 0.5s, text-shadow 0.5s;
  -o-transition: min-width 0.4s, color 0.5s, text-shadow 0.5s;
  -moz-transition: min-width 0.4s, color 0.5s, text-shadow 0.5s;
  transition: min-width 0.4s, color 0.5s, text-shadow 0.5s;
  text-align: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: baseline;
  -moz-box-align: baseline;
  align-items: baseline;
}

.booming .current-odd-holder .current-odd {
  color: #ffa3c4;
  text-shadow: 0 0.2rem 0.4rem rgba(11, 19, 54, 0.4);
}

.current-odd-holder .current-odd div {
  min-width: 3.6rem;
}

.current-odd-holder .current-odd span {
  position: absolute;
  left: 100%;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  margin: 0 0 0 0.5pc;
}

.current-round-holder {
  position: absolute;
  -webkit-border-radius: 0.6rem;
  -moz-border-radius: 0.6rem;
  border-radius: 0.6rem;
  padding: 0 1.4rem;
  height: 4rem;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.15);
  top: 1.2rem;
  left: 1.2rem;
  -webkit-transform: translate3d(0, -200%, 0);
  -moz-transform: translate3d(0, -200%, 0);
  transform: translate3d(0, -200%, 0);
  opacity: 0;
  -webkit-animation: current-round-holder-reveal 0.5s forwards;
  -moz-animation: 0.5s current-round-holder-reveal forwards;
  -o-animation: 0.5s current-round-holder-reveal forwards;
  animation: current-round-holder-reveal 0.5s forwards;
  z-index: 10000001;
}

.device-mobile .current-round-holder {
  background: none;
  font-size: 1.8rem;
  left: 0;
  top: 0.6rem;
}

.current-round-holder.p-right {
  right: 1.2rem;
  left: auto;
}

.device-mobile .current-round-holder.p-right {
  right: 0;
}

@-webkit-keyframes current-round-holder-reveal {
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@-moz-keyframes current-round-holder-reveal {
  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@-o-keyframes current-round-holder-reveal {
  to {
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes current-round-holder-reveal {
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.current-round-holder span + span {
  margin: 0 0 0 0.8rem;
}

.current-round-holder span.highlighted {
  color: #fdbf51;
}

.waiting-circle {
  width: 19.6rem;
  height: 19.6rem;
  position: relative;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, -webkit-transform 0.4s;
  -o-transition: 0.4s opacity, 0.4s -o-transform;
  -moz-transition: 0.4s transform, 0.4s opacity, 0.4s -moz-transform;
  transition: transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s,
    -moz-transform 0.4s, -o-transform 0.4s;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  font: 6rem/1 "Lato", sans-serif;
  color: #fff;
  -webkit-text-stroke: 0.2rem #15114a;
}

.startWaitingAnimations .waiting-circle {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

@media (orientation: portrait) and (max-aspect-ratio: 13/9) {
  .device-mobile .startWaitingAnimations .waiting-circle {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.waiting-circle li {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}

.waiting-circle li svg {
  width: 100%;
  height: 100%;
}

.waiting-circle li path {
  stroke-linecap: round;
  stroke-width: 0.15rem;
  fill-opacity: 0;
}

.waiting-circle li path:first-of-type {
  stroke: hsla(0, 0%, 100%, 0.1);
  stroke-dasharray: 295.31px, 295.31px;
  stroke-dashoffset: 0;
}

.waiting-circle li path:nth-of-type(2) {
  stroke-dasharray: 0, 295.31px;
  stroke-dashoffset: 0;
}

.startWaitingAnimations .waiting-circle li path:nth-of-type(2) {
  -webkit-animation: waitingPath 5s linear forwards;
  -moz-animation: waitingPath linear 5s forwards;
  -o-animation: waitingPath linear 5s forwards;
  animation: waitingPath 5s linear forwards;
}

.waiting-circle li:first-child .wc-arrow .icon {
  color: #fe7275;
}

.startWaitingAnimations .waiting-circle li:first-child .wc-arrow .icon {
  -webkit-animation: wc-arrow-animation-0 5s linear forwards;
  -moz-animation: wc-arrow-animation-0 linear 5s forwards;
  -o-animation: wc-arrow-animation-0 linear 5s forwards;
  animation: wc-arrow-animation-0 5s linear forwards;
}

@-webkit-keyframes wc-arrow-animation-0 {
  0%,
  50%,
  to {
    color: #fe7275;
  }

  75% {
    color: #fd4a95;
  }

  25% {
    color: #ff9954;
  }

  95% {
    -webkit-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #fe7275;
    -webkit-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

@-moz-keyframes wc-arrow-animation-0 {
  0%,
  50%,
  to {
    color: #fe7275;
  }

  75% {
    color: #fd4a95;
  }

  25% {
    color: #ff9954;
  }

  95% {
    -moz-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #fe7275;
    -moz-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

@-o-keyframes wc-arrow-animation-0 {
  0%,
  50%,
  to {
    color: #fe7275;
  }

  75% {
    color: #fd4a95;
  }

  25% {
    color: #ff9954;
  }

  95% {
    -o-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #fe7275;
    -o-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

@keyframes wc-arrow-animation-0 {
  0%,
  50%,
  to {
    color: #fe7275;
  }

  75% {
    color: #fd4a95;
  }

  25% {
    color: #ff9954;
  }

  95% {
    -webkit-transform: translate(-60%, -18%) scale(1);
    -moz-transform: translate(-60%, -18%) scale(1);
    -o-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #fe7275;
    -webkit-transform: translate(-60%, -18%) scale(0);
    -moz-transform: translate(-60%, -18%) scale(0);
    -o-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

.waiting-circle li:nth-child(2) {
  border: 1.2rem solid transparent;
}

.waiting-circle li:nth-child(2) .wc-arrow .icon {
  color: #a454e8;
}

.startWaitingAnimations .waiting-circle li:nth-child(2) .wc-arrow .icon {
  -webkit-animation: wc-arrow-animation-1 5s linear forwards;
  -moz-animation: wc-arrow-animation-1 linear 5s forwards;
  -o-animation: wc-arrow-animation-1 linear 5s forwards;
  animation: wc-arrow-animation-1 5s linear forwards;
}

@-webkit-keyframes wc-arrow-animation-1 {
  0%,
  50%,
  to {
    color: #a454e8;
  }

  75% {
    color: #ff5cee;
  }

  25% {
    color: #494be1;
  }

  95% {
    -webkit-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #a454e8;
    -webkit-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

@-moz-keyframes wc-arrow-animation-1 {
  0%,
  50%,
  to {
    color: #a454e8;
  }

  75% {
    color: #ff5cee;
  }

  25% {
    color: #494be1;
  }

  95% {
    -moz-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #a454e8;
    -moz-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

@-o-keyframes wc-arrow-animation-1 {
  0%,
  50%,
  to {
    color: #a454e8;
  }

  75% {
    color: #ff5cee;
  }

  25% {
    color: #494be1;
  }

  95% {
    -o-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #a454e8;
    -o-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

@keyframes wc-arrow-animation-1 {
  0%,
  50%,
  to {
    color: #a454e8;
  }

  75% {
    color: #ff5cee;
  }

  25% {
    color: #494be1;
  }

  95% {
    -webkit-transform: translate(-60%, -18%) scale(1);
    -moz-transform: translate(-60%, -18%) scale(1);
    -o-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #a454e8;
    -webkit-transform: translate(-60%, -18%) scale(0);
    -moz-transform: translate(-60%, -18%) scale(0);
    -o-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

.waiting-circle li:nth-child(3) {
  border: 2.4rem solid transparent;
}

.waiting-circle li:nth-child(3) .wc-arrow .icon {
  color: #5e7bee;
}

.startWaitingAnimations .waiting-circle li:nth-child(3) .wc-arrow .icon {
  -webkit-animation: wc-arrow-animation-2 5s linear forwards;
  -moz-animation: wc-arrow-animation-2 linear 5s forwards;
  -o-animation: wc-arrow-animation-2 linear 5s forwards;
  animation: wc-arrow-animation-2 5s linear forwards;
}

@-webkit-keyframes wc-arrow-animation-2 {
  0%,
  50%,
  to {
    color: #5e7bee;
  }

  75% {
    color: #8356ee;
  }

  25% {
    color: #38a0ee;
  }

  95% {
    -webkit-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #5e7bee;
    -webkit-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

@-moz-keyframes wc-arrow-animation-2 {
  0%,
  50%,
  to {
    color: #5e7bee;
  }

  75% {
    color: #8356ee;
  }

  25% {
    color: #38a0ee;
  }

  95% {
    -moz-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #5e7bee;
    -moz-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

@-o-keyframes wc-arrow-animation-2 {
  0%,
  50%,
  to {
    color: #5e7bee;
  }

  75% {
    color: #8356ee;
  }

  25% {
    color: #38a0ee;
  }

  95% {
    -o-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #5e7bee;
    -o-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

@keyframes wc-arrow-animation-2 {
  0%,
  50%,
  to {
    color: #5e7bee;
  }

  75% {
    color: #8356ee;
  }

  25% {
    color: #38a0ee;
  }

  95% {
    -webkit-transform: translate(-60%, -18%) scale(1);
    -moz-transform: translate(-60%, -18%) scale(1);
    -o-transform: translate(-60%, -18%) scale(1);
    transform: translate(-60%, -18%) scale(1);
  }

  to {
    color: #5e7bee;
    -webkit-transform: translate(-60%, -18%) scale(0);
    -moz-transform: translate(-60%, -18%) scale(0);
    -o-transform: translate(-60%, -18%) scale(0);
    transform: translate(-60%, -18%) scale(0);
  }
}

.startWaitingAnimations .waiting-circle .wc-arrow {
  -webkit-animation: rotate 5s linear forwards;
  -moz-animation: rotate linear 5s forwards;
  -o-animation: rotate linear 5s forwards;
  animation: rotate 5s linear forwards;
}

.waiting-circle .wc-arrow .icon {
  -webkit-text-stroke: 0;
  font-size: 1.6rem;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-60%, -18%);
  -moz-transform: translate(-60%, -18%);
  -o-transform: translate(-60%, -18%);
  transform: translate(-60%, -18%);
}

.startWaitingAnimations .waiting-circle .wc-arrow .icon.icon-ghost:before {
  -webkit-transform: rotate(90deg) scale(1);
  -moz-transform: rotate(90deg) scale(1);
  -o-transform: rotate(90deg) scale(1);
  transform: rotate(90deg) scale(1);
}

.waiting-circle .wc-arrow .icon.icon-deer {
  font-size: 1.8rem;
}

.startWaitingAnimations .waiting-circle .wc-arrow .icon.icon-deer:before {
  -webkit-transform: rotate(180deg) translateY(-20%) scale(1);
  -moz-transform: rotate(180deg) translateY(-20%) scale(1);
  -o-transform: rotate(180deg) translateY(-20%) scale(1);
  transform: rotate(180deg) translateY(-20%) scale(1);
}

.waiting-circle .wc-arrow .icon:before {
  display: block;
  -webkit-transform: rotate(-90deg) scale(0);
  -moz-transform: rotate(-90deg) scale(0);
  -o-transform: rotate(-90deg) scale(0);
  transform: rotate(-90deg) scale(0);
}

.startWaitingAnimations .waiting-circle .wc-arrow .icon:before {
  -webkit-transform: rotate(-90deg) scale(1);
  -moz-transform: rotate(-90deg) scale(1);
  -o-transform: rotate(-90deg) scale(1);
  transform: rotate(-90deg) scale(1);
}

.chart svg {
  /* width: 100%; */
  height: 100%;
}

.chart svg path {
  stroke: none;
}

.chart svg .tick {
  font: 1.4rem "Lato", sans-serif;
}

.chart svg .grid line {
  stroke-opacity: 0.1;
}
